import React from 'react'
import { Container, Header } from 'semantic-ui-react'
import Layout from '../../components/Layout'
import Shop from './shop'

const shop = props => {
  return (
    <Layout>
      <Container fluid style={{ margin: 40, padding: '0 40px' }}>
        <Header as="h2">Shop</Header>
        <Shop />
      </Container>
    </Layout>
  )
}

export default shop
